(function () {
  'use strict';

  class Utils {
    constructor($rootScope, $timeout, $window) {
      const vm = this;
      vm.$rootScope = $rootScope;
      vm.$timeout = $timeout;
      vm.$window = $window;
    }
    isMobile() {
      let vm = this;
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(vm.$window.navigator.userAgent);
    }
    isIOS() {
      let vm = this;
      return (/iPad|iPhone|iPod/).test(vm.$window.navigator.userAgent) && !vm.$window.MSStream;
    }
    roleCheck(arr) {
      const vm = this;
      if (!vm.$rootScope.user) {
        return false;
      }
      return arr.indexOf(vm.$rootScope.user.role) !== -1;
    }
    dataURItoBlob(dataURI) {
      let binary = atob(dataURI.split(',')[1]),
          array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
    }
    resourceTypes() {
      return ['page', 'poll', 'canvas', 'results'];
    }
    drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {
      if (arguments.length === 2) {
        x = y = 0;
        w = ctx.canvas.width;
        h = ctx.canvas.height;
      }
      // default offset is center
      offsetX = angular.isNumber(offsetX) ? offsetX : 0.5;
      offsetY = angular.isNumber(offsetY) ? offsetY : 0.5;
      // keep bounds [0.0, 1.0]
      if (offsetX < 0) {
        offsetX = 0;
      }
      if (offsetY < 0) {
        offsetY = 0;
      }
      if (offsetX > 1) {
        offsetX = 1;
      }
      if (offsetY > 1) {
        offsetY = 1;
      }
      let iw = img.width,
          ih = img.height,
          r = Math.min(w / iw, h / ih),
          nw = iw * r,
          nh = ih * r,
          cx, cy, cw, ch, ar = 1;
      // decide which gap to fill
      if (nw < w) {
        ar = w / nw;
      }
      if (Math.abs(ar - 1) < 1e-14 && nh < h) {
        ar = h / nh;
      }
      nw *= ar;
      nh *= ar;
      // calc source rectangle
      cw = iw / (nw / w);
      ch = ih / (nh / h);
      cx = (iw - cw) * offsetX;
      cy = (ih - ch) * offsetY;
      // make sure source rectangle is valid
      if (cx < 0) {
        cx = 0;
      }
      if (cy < 0) {
        cy = 0;
      }
      if (cw > iw) {
        cw = iw;
      }
      if (ch > ih) {
        ch = ih;
      }
      // fill image in dest. rectangle
      ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
    }
    setModule(id) {
      let vm = this;
      vm.moduleId = id;
    }
    getModule() {
      let vm = this;
      return vm.moduleId;
    }
    static normalizePosition(evt, parent) {
      let position = {};
      position.x = evt.targetTouches ? evt.targetTouches[0].pageX : evt.clientX;
      position.y = evt.targetTouches ? evt.targetTouches[0].pageY : evt.clientY;

      while (parent.offsetParent) {
        position.x -= parent.offsetLeft - parent.scrollLeft;
        position.y -= parent.offsetTop - parent.scrollTop;

        parent = parent.offsetParent;
      }

      return position;
    }
    matrixSetup(data, n) {
      let grid = [], x = data.length, col, row = -1;
      for (let i = 0; i < x; i++) {
        col = i % n;
        if (col === 0) {
          grid[++row] = [];
        }
        grid[row][col] = data[i];
      }
      return grid;
    }
    setTags(e, arr, bol) {
      let vm = this,
          obj, x, y, eWidth, eHeight, mobilePos;

      if (e.target.tagName === 'CANVAS' && bol) {
        eWidth = e.target.width || e.srcElement.width;
        eHeight = e.target.height || e.srcElement.height;
        if (vm.$rootScope.isMobile) {
          mobilePos = Utils.normalizePosition(e, e.target.offsetParent);
        }
        x = Math.ceil((mobilePos ? mobilePos.x : e.offsetX) / eWidth * 100);
        y = Math.ceil((mobilePos ? mobilePos.y : e.offsetY) / eHeight * 100);

        obj = {
          content: '',
          offsetX: x,
          offsetY: y
        };
        arr.push(obj);
        vm.$timeout(function () {
          e.path[1].children[e.path[1].children.length - 2].children[0].focus();
        });
      }
    }
    removeTags(tag, arr) {
      let tagIndex = arr.indexOf(tag);
      if (tagIndex !== -1) {
        arr.splice(tagIndex, 1);
      }
    }
    setClassroom(classroom_id) {
      let vm = this;
      vm.classroom_id = classroom_id;
    }
    getClassroom() {
      let vm = this;
      return vm.classroom_id;
    }
  }

  /**
   * @ngdoc service
   * @name bioscann.service:Utils
   *
   * @description
   *
   */
  angular
    .module('bioscann')
    .service('Utils', Utils);
}());
